import { Box } from '@chakra-ui/react';
import { Flex, Spacer, Text,  } from '@chakra-ui/react'
import CircularProgress from './CircularProgress';

const TriviaHeader = ({ title, fullInterval, noBg, noSticky }) => {
    return (
        <Box w="100%" maxW="500px" h="64px" p="20px" bg={!noBg && "#000"} bgOpacity={noBg ? "0%": "100%"} position={`${!noSticky ? "sticky" : "absolute"}`} top="0px" zIndex="100">
            <Flex>
                <Box w='30px'>
                    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.6452 14.8289L10.1876 11.5069V16.7198C7.93528 17.1776 7.48584 15.5166 7.48584 15.5166C7.03575 13.2257 8.89324 13.0536 8.89324 13.0536V9.5023L0 3.25879C0 3.25879 4.78403 10.762 4.78403 10.7627C3.95543 10.9341 3.34229 11.4875 3.09723 11.7086C3.0734 11.7301 3.05305 11.7485 3.03633 11.763C2.59135 12.1495 2.2327 12.6253 1.98369 13.1595C1.73468 13.6937 1.6009 14.2743 1.59104 14.8636C1.51977 18.6095 4.06556 21.0153 7.20461 21.0153H15.6452V14.8289ZM10.3564 10.2106L15.814 13.5327V8.32101C18.0664 7.86257 18.5158 9.52361 18.5158 9.52361C18.9665 11.8152 17.109 11.9866 17.109 11.9866V15.536L26.0016 21.7795L21.2176 14.2762C22.046 14.1048 22.6596 13.5512 22.9044 13.3303C22.9282 13.3088 22.9486 13.2904 22.9653 13.2759C23.4103 12.8897 23.7692 12.4143 24.0186 11.8805C24.2681 11.3467 24.4025 10.7664 24.4132 10.1772C24.4844 6.43075 21.938 4.0249 18.7996 4.0249H10.3564V10.2106Z" fill="#F6AC37"/>
                    </svg>
                </Box>
                <Spacer />
                <Box w='200px' align="center">
                    <Text fontSize="12px" fontFamily="Candal" letterSpacing={0.48} textTransform="uppercase" fontWeight="400" color="#FFA031" lineHeight="20px">{title}</Text>
                </Box>
                <Spacer />
                {fullInterval && (
                    <Box w='40px'>
                        <CircularProgress duration={fullInterval} size="35" />
                    </Box>
                )}
            </Flex>
        </Box>
    )
}

export default TriviaHeader;    