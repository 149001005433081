import React, { useEffect } from 'react';
import MobileLayout from '../../components/MobileLayout';
import TriviaTitle from '../../components/Misc/Title';

import TriviaHeader from '../../components/Misc/Header';
import GameGrid from '../../components/GameView/GameGrid';
import Result from '../../components/GameView/Result';
import { VStack } from '@chakra-ui/layout';
import Leaderboard from '../../components/GameView/Leaderboard';
import LoadingQuestion from '../../components/GameView/LoadingQuestion';
import RankLeaderboard from '../../components/Leaderboard';
import Popup from '../../components/Misc/Popup';
import { useDisclosure } from '@chakra-ui/hooks';
import { Button } from '@chakra-ui/button';
import { useDispatch } from 'react-redux';

const Other = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };


    useEffect(() => {
    if (window !== undefined)
    {
        window.addEventListener("beforeunload", alertUser);
        window.addEventListener("pagehide", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
            window.addEventListener("pagehide", alertUser);
        };
    }
    }, []);

    const openPopup = () => {
        dispatch(onOpen)
    }

      
    const leaderboard = [
        {score: 1000, rank: 1, name: "AAAA"},
        {score: 900, rank: 2, name: "BBBB"},
        {score: 900, rank: 2, name: "Kim Tante"},
        {score: 900, rank: 2, name: "Kim Tante"},
        {score: 900, rank: 2, name: "BBBB"},
        {score: 900, rank: 2, name: "BBBB"},
        {score: 900, rank: 2, name: "BBBB"},
    ]
    return (
        <MobileLayout showBg showGreyBg showRadialGradient >
        <TriviaHeader title={"Daftar Pemenang"}  />
            
            {/* <LoadingQuestion name="Bora Gembira" duration={3000} /> */}
            {/* <RankLeaderboard leaderboard={leaderboard} /> */}
            <VStack mt="10px" paddingLeft="20px" paddingRight="20px">
                <Result
                    score={1000}
                    rank={13}
                    
                />
                <Leaderboard leaderboard={leaderboard} duration={5000} rank={7} score={100} name="Bora Gembira" />
            </VStack>
{/* 
            <Popup isOpen={isOpen} title="title" content="content" onClose={onClose} />
            <Button onClick={openPopup}>Open Popup</Button> */}
        </MobileLayout>
    );
};

export default Other;