import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import './style.css'
import Routes from "./Routes";

// Create a custom Chakra UI theme to set the max-width
const theme = extendTheme({
  styles: {
    global: {
      body: {
        maxWidth: '500px', // Set your desired max-width for mobile here
        margin: '0 auto',  // Center the content horizontally
        fonts: {
          body: `'Airbnb', sans-serif`,
        },
        background: '#1B1B1B',
      },
    },
  },
  colors: {
    orange: {
      500: '#F6AC37'
    },
    black: {
      500: '#212121'
    }
  } 
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes />
    </ChakraProvider>
  );
}

export default App;
