import { Box, Flex, Spacer, Text } from "@chakra-ui/layout";
import { Progress } from "@chakra-ui/progress";
import { Table, Tbody, Td, Tr } from "@chakra-ui/table";
import { useEffect, useState } from "react";
import { BgRankSolid } from "../Leaderboard/RankSlide";

const BoxBg = () => {
    return (
        <svg width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 6C0 3.79086 1.79086 2 4 2H26C28.2091 2 30 3.79086 30 6V28.8075C30 30.6792 28.7034 32.31 26.8674 32.6737C18.0241 34.4254 11.9556 34.4561 3.11637 32.6816C1.28774 32.3145 0 30.6871 0 28.8219V6Z" fill="#D4942D"/>
        <path d="M0 4C0 1.79086 1.79086 0 4 0H26C28.2091 0 30 1.79086 30 4V26.8075C30 28.6792 28.7034 30.31 26.8674 30.6737C18.0241 32.4254 11.9556 32.4561 3.11637 30.6816C1.28774 30.3145 0 28.6871 0 26.8219V4Z" fill="url(#paint0_radial_529_2808)"/>
        <defs>
        <radialGradient id="paint0_radial_529_2808" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.3819e-07 1.5) rotate(50.9061) scale(30.9233 30.9233)">
        <stop stop-color="#FFDCA4"/>
        <stop offset="1" stop-color="#F6AC37"/>
        </radialGradient>
        </defs>
        </svg>
    )
}

const Leaderboard = ({rank, score, name, duration, leaderboard}) => {
    const durationInSecond = duration / 1000;
    const [timeLeft, setTimeLeft] = useState(durationInSecond);
    useEffect(() => {
        if (timeLeft > 0)
        {
            const intervalId  = setInterval(() => {
            setTimeLeft((t) => t - 0.03);
            }, 30);
            return () => clearInterval(intervalId);
        }
    }, [timeLeft]);
    
    return (
        <Box w="100%" borderTopRadius={4} pt="30px" position="absolute" bottom="0px" left="0px" right="0px" top="280px" bg="white">
            <Table variant="unstyled" overflow="auto">
                <Tbody>
                    {leaderboard?.map((player, index) => 
                        index < 5 && (
                            <Tr key={index}>
                                <Td width="50px" paddingLeft="10px" paddingRight="15px">
                                    <Box w="50px" position="relative" display="flex" align="center" alignItems="center" justifyContent="center">
                                        {index === 0 ? <BoxBg /> : <BgRankSolid />}
                                        <Text fontFamily="Candal" position="absolute" fontSize="12px" color="#212121">{index + 1}</Text>
                                    </Box>
                                </Td>
                                <Td paddingLeft="0px" paddingRight="14px" paddingY="0px">
                                    <Flex borderRadius="8px" width="100%" display="inline-flex" paddingY="17px" paddingX="12px" background="#FFF9F0" >
                                        <Box color="#212121" fontSize="16px" fontWeight="600">{player.name}</Box>
                                        <Spacer />
                                        <Box color="#86868B" fontSize="12px" fontWeight="1000" >{player.score} pt</Box>
                                    </Flex>
                                </Td>
                            </Tr>
                        )
                    )}
                </Tbody>
            </Table>
            <Box position="sticky" bottom="0px" width="100%" top="100vh" >
                <Progress value={(durationInSecond-timeLeft)/durationInSecond*100} size='xs' colorScheme='orange' width="100%" />
                <Box p="20px">
                    <Flex justifyContent="center" alignItems="center">
                        <Box w='10%' fontSize="12px" fontWeight="400" fontFamily="Candal">
                            {rank}
                        </Box>
                        <Spacer />
                        <Box w='70%'>
                            <Text fontSize="16px" letterSpacing={0.48} fontWeight="800" color="#212121" lineHeight="20px">{name}</Text>
                        </Box>
                        <Spacer />
                        <Box w='25%' display="flex" alignContent="center" justifyContent="center" >
                            <Box bg="#F6AC37" borderRadius={4} pt="5px" pb="5px" pl="8px" pr="8px" textAlign="right">
                                <Text fontSize="16px" color="#212121" fontWeight="1000" margin="0 auto">{score} pt</Text>
                            </Box>
                            
                        </Box>
                    
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}

export default Leaderboard;