import React, { useEffect, useState } from 'react';
import MobileLayout from '../components/MobileLayout';
import TriviaHeader from '../components/Misc/Header';
import RankLeaderboard from '../components/Leaderboard';
import { Box } from '@chakra-ui/layout';
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import QueryString from 'qs';
import useWindowSize from 'react-use/lib/useWindowSize'
import { lazy } from "react";

// const ConfettiComponent = lazy(() => import("react-confetti"));


const ResultPage = () => {
    const endpoint = process.env.REACT_APP_ENDPOINT;
    const [renderConfetti, setRenderConfetti] = useState(false);
    const { width, height } = useWindowSize();
    const [leaderboard, setLeaderboard] = useState([]);
    const location = useLocation();
    const qs = QueryString.parse(location.search, { ignoreQueryPrefix: true })
    const roomId = qs.rId;
    const playerId = qs.pId;
    
    useEffect(() => {
        if (roomId)
        {
            axios.get(`${endpoint}quiz/result/${roomId}`).then((response) => {
                if(response.status === 200)
                {
                    setLeaderboard(response.data)
                }
            });
        }
    }, [endpoint, roomId]);


    useEffect(() => {
        if (leaderboard.length) {
            setRenderConfetti(true);
        }
    }, [leaderboard])

    return (
        <MobileLayout showRadialGradientBg >
            {leaderboard.length && (
                <Box overflow="hidden" >
                    <TriviaHeader title={"Daftar Pemenang"} noBg noSticky />
                    <RankLeaderboard playerId={playerId} leaderboard={leaderboard} />
                </Box>
            )}

            {/* {renderConfetti && (
                <ConfettiComponent
                    initialVelocityX={1}
                    initialVelocityY={5}
                    gravity={0.03}
                    recycle={false}
                    tweenDuration={10000}
                    numberOfPieces={500}
                    width={width > 500 ? 500 : width}
                    height={height}
                />
            )} */}
            
        </MobileLayout>
    );
};

export default ResultPage;