
import { Box, Text, Image, VStack } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'

const Question = ({ image, title }) => {
    const txtRef = useRef();
    const showBoth = image && title
    const onlyTitle = !image && title
    const onlyImage = image && !title

   

    useEffect(() => {
        if (onlyTitle)
        {
            const charLength = title.length;
            if (charLength > 20)
                txtRef.current.style.fontSize = "20px";
            else
                txtRef.current.style.fontSize = "30px";
        }
    }, []);

    if (showBoth)
        return (
            <VStack m="20px">
                <Box fontSize="20px" fontWeight="300" color="white">{title}</Box>
                <Box h="188px" borderRadius={4} backgroundImage={image} w="100%" 
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    bgSize="cover"
                 />
            </VStack>
        )
    else 
        return (
            <Box bg={`${onlyTitle && "linear-gradient(180deg, #F6AC37 29.1%, #E5A018 83.58%)"}`} width="calc(100% - 40px)" justifyContent="center" h="254px" margin="20px" borderRadius={4} textAlign="center" display="flex" alignItems="center">
                {onlyImage && (
                    <Image src={image} alt="Question" />
                )}

                {onlyTitle && (
                    <Text ref={txtRef} as="h1" color="#212121" fontSize="28px" fontWeight="700" align="center" verticalAlign="middle" p="20px">{title}</Text>
                )}
            </Box>
        )
}

export default Question;