import { Box, Center, Image } from "@chakra-ui/react";
import isUrl from 'is-url';

// Parse HTML entities in string
export const parseEntities = (content) =>
  new DOMParser().parseFromString(content, "text/html").body.innerText;

const Option = ({ index, content, isSelected, selectedAnswer, onClickHandler, height, columns }) => {
  return (
    <Box display="inline-flex" opacity={selectedAnswer && !isSelected ? "0.6": "1" } bg={isSelected ? "#F6AC37":"white"} borderRadius={8} p="8px" h={height} w='100%' filter="drop-shadow(0px 4px 0px #F6AC37)" position="relative" onClick={onClickHandler}>
      <Center as="span" fontFamily="Candal" position="absolute" w="22px" alignContent="center" h="22px" borderRadius="22px" bg="#000" color="#F6AC37" fontSize="12px" lineHeight="20px" fontWeight={400}>
          {index === 0 && "A"}
          {index === 1 && "B"}
          {index === 2 && "C"}
          {index === 3 && "D"}
      </Center>
      <Center display="flex" alignItems="center" h="100%" w="100%" justifyContent="center">
        {isUrl(parseEntities(content)) ? 
        (
          <Box margin="0 auto" color="#fff" display="inline-flex"><Image h="64px" src={parseEntities(content)} alt={index} /></Box>
        ) : (
          <Box as="span" color="black" display="inline-flex" textAlign="center">{parseEntities(content)}</Box>
        )}
      </Center>
      <Box  className={`cardshape2 ${isSelected ? "selected" : "normal"} ${columns === 1 ? "long" : ""}`}/>
    </Box>
  );
};

export default Option;
