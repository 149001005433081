export const Correct = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M29.8397 10.1072C29.8397 10.5834 29.673 10.9882 29.3397 11.3215L16.4111 24.2501L13.9826 26.6786C13.6492 27.012 13.2445 27.1786 12.7683 27.1786C12.2921 27.1786 11.8873 27.012 11.554 26.6786L9.12542 24.2501L2.66113 17.7858C2.3278 17.4524 2.16113 17.0477 2.16113 16.5715C2.16113 16.0953 2.3278 15.6905 2.66113 15.3572L5.0897 12.9286C5.42304 12.5953 5.8278 12.4286 6.30399 12.4286C6.78018 12.4286 7.18494 12.5953 7.51828 12.9286L12.7683 18.1965L24.4826 6.46436C24.8159 6.13102 25.2207 5.96436 25.6968 5.96436C26.173 5.96436 26.5778 6.13102 26.9111 6.46436L29.3397 8.89293C29.673 9.22626 29.8397 9.63102 29.8397 10.1072Z" fill="url(#paint0_linear_128_3762)"/>
        <defs>
            <linearGradient id="paint0_linear_128_3762" x1="16.0004" y1="5.96436" x2="16.0004" y2="27.1786" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5B5B5B"/>
            <stop offset="1" stop-color="#010101"/>
            </linearGradient>
        </defs>
        </svg>
    )
}