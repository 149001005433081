import { Box } from "@chakra-ui/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import TriviaHeader from "../../components/Misc/Header";
import QueryString from "qs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MobileLayout from "../../components/MobileLayout";
import RankResultAdmin from "../../components/Leaderboard/RankResultAdmin";
import { CookiesProvider, useCookies } from "react-cookie";
import { useToast } from "@chakra-ui/react";

const AdminResult = () => {
    const endpoint = process.env.REACT_APP_ENDPOINT;
    const toast = useToast();
    const [isLoggedIn, setIsLoggedIn] = useState(null); 
    const [cookies, setCookie, removeCookie] = useCookies(['rrq-trivia']);
    const location = useLocation();
    const [leaderboard, setLeaderboard] = useState([]);
    const qs = QueryString.parse(location.search, { ignoreQueryPrefix: true })
    const roomId = qs.rId;
    useEffect(() => {
        if (roomId)
        {
            axios.get(`${endpoint}quiz/result/${roomId}`).then((response) => {
                if(response.status === 200)
                {
                    setLeaderboard(response.data)
                }
            });
        }
    }, [endpoint, roomId]);

    useEffect(() => {
        if (cookies.name)
        {
            setIsLoggedIn(true)
        }
        else {
            toast({
                title: 'Unauthenticated',
                description: "You are not logged in, please relogin and refresh this page",
                status: 'error',
                duration: 15000,
                isClosable: true,
            })
            setIsLoggedIn(false)
        }
      }, [])

    return (
        <CookiesProvider>
            <MobileLayout showRadialGradientBg >
                {(isLoggedIn && cookies.name && leaderboard.length > 0) && (
                    <Box overflow="scroll" height="100vh" >
                        <TriviaHeader title={"Daftar Pemenang"} noBg noSticky />
                        <RankResultAdmin leaderboard={leaderboard} />
                    </Box>
                )}
            </MobileLayout>
        </CookiesProvider>
    );
};

export default AdminResult;