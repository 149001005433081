export const Incorrect = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M28 23.1983L20.7949 16L28 8.80171L23.1983 4L16 11.2017L8.79829 4L4 8.80171L11.1983 16L4 23.1983L8.79829 28L16 20.7983L23.1983 28L28 23.1983Z" fill="url(#paint0_linear_302_4820)"/>
        <defs>
            <linearGradient id="paint0_linear_302_4820" x1="16" y1="4" x2="16" y2="28" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5B5B5B"/>
            <stop offset="1" stop-color="#010101"/>
            </linearGradient>
        </defs>
        </svg>
    )
}