import { VStack } from "@chakra-ui/layout";
import RankSlide from "./RankSlide";
import RankGraph from "./RankGraph";

const RankLeaderboard = ({playerId, leaderboard}) => {
    const player = leaderboard?.find((obj) => obj.id === playerId);
    if (player) {
        const rank = leaderboard?.findIndex((obj) => obj.id === player.id);
        player.rank = rank + 1;
    }
    return (
        <VStack overflow="hidden" gap="20px" display="flex" bg="radial-gradient(73.78% 74.98% at 0% 0%, #4A4A4B 0%, #1B1B1B 100%)" 
            h="100vh" position="absolute" w="100%" alignItems="center" textAlign="center" justifyContent="center" p="20px" zIndex={1}>
            <RankSlide data={leaderboard?.slice(3,8)} player={player} />
            <RankGraph data={leaderboard?.slice(0,3)}/>
        </VStack>
    )
}

export default RankLeaderboard;