import { Image } from "@chakra-ui/image";
import { Box, Text, VStack } from "@chakra-ui/layout";


const Title = ({props, children}) => {
    return (
        <Box color="white" wordBreak="break-word" fontSize="14px" margin="0 auto" {...props} fontWeight={700}>{children}</Box>
    )
}
const Score = ({props, children}) => {
    return (
        <Text zIndex={4} position="absolute" color="black" {...props} fontSize="12px" margin="0px auto" fontWeight={500}>{children}</Text>
    )
}

const RankGraph = ({data}) => {
    return (
        <Box position="absolute" h="calc(50vh - 54px)" w="100%" top="64px" zIndex={50} display="flex" alignItems="flex-end">
            {data?.[0] && (
                <Box w="146px" minH="235px" position="absolute" left="calc(50% - 73px)" zIndex={11}>
                    <VStack gap="1px" alignItems="end">
                        <Box w="111px" h="90px" bgImage="url(/images/1stplace-crown.png)" margin="0 auto" />
                        <Title w="146px">{data?.[0]?.name}</Title>
                        <Box zIndex={3} w="146px" h="130px" textAlign="center" justifyContent="center" display="flex">
                            <Score>{data?.[0]?.score} pt</Score>
                            <Image src="/images/1stplace.png" />  
                        </Box>
                    </VStack>
                </Box>
            )}
            {data?.[2] && (
                <Box w="112px" minH="170px" position="absolute" left="calc(50% - 165px)" zIndex={12}>
                    <VStack gap="1px" alignItems="end">
                        <Box w="111px" h="90px" bgImage="url(/images/3rdplace-crown.png)" margin="0 auto"></Box>
                        <Title w="111px">{data?.[2]?.name}</Title>
                        <Box zIndex={3} w="112px" h="55px" textAlign="center" justifyContent="center" display="flex">
                            <Score>{data?.[2]?.score} pt</Score>
                            <Image src="/images/3rdplace.png" />
                        </Box>
                    </VStack>
                </Box>
            )}
            {data?.[1] && (
                <Box w="112px" minH="205px" position="absolute" left="calc(50% + 50px)" zIndex={12}>
                    <VStack gap="1px" alignItems="end">
                        <Box w="111px" minH="90px" bgImage="url(/images/2ndplace-crown.png)" margin="0 auto"></Box>
                        <Title w="111px">{data?.[1]?.name}</Title>
                        <Box zIndex={3} w="112px" h="85px" textAlign="center" justifyContent="center" display="flex">
                            <Score>{data?.[1]?.score} pt</Score>
                            <Image src="/images/2ndplace.png" w="112px" h="85px" />
                        </Box>
                    </VStack>
                </Box>
            )}
        </Box>
        
    )
}

export default RankGraph;