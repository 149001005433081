
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

const CircularProgress = ({ duration, size }) => {
    const [timeLeft, setTimeLeft] = useState(duration);
    const circRef = useRef();
    useEffect(() => {
        const intervalId  = setInterval(() => {
            if (timeLeft >= 0)
                setTimeLeft((t) => t - 1);
          }, 1000);

        
        return () => { 
            clearInterval(intervalId);
        }
    }, [])
    
    useEffect(() => {
        if (timeLeft >= 0)
        {
            gsap.to(circRef.current, {
                "--p": `${((duration - timeLeft) / duration) * 100}%`,
                duration: 1,
                ease: 'expo.out'
            });
        }
    }, [timeLeft])
    return (
        <div className="circular-pbar" ref={circRef} style={{width: `${size}px`, height: `${size}px`}} >
            <span className="circular-pbar-counter">{ timeLeft > 0 ? timeLeft : 0 }</span>
        </div>
    )
};

export default CircularProgress;