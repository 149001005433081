import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  phone: null,
  email: null,
  type: null,
  numOfQuestion: null,
  quizId: null,
  roomId: null,
  socketID: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setNumOfQuestion: (state, action) => {
      state.numOfQuestion = action.payload
    },
    setQuizId: (state, action) => {
      state.quizId = action.payload
    },
    setRoomId: (state, action) => {
      state.roomId = action.payload
    },
    setSocketID: (state, action) => {
      state.socketID = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setName, setPhone, setEmail, setType, setSocketID, setNumOfQuestion, setQuizId, setRoomId } = userSlice.actions;

export default userSlice.reducer;
