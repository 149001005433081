
import { SimpleGrid, VStack } from '@chakra-ui/react';
import Question from '../Question/Question';
import Option from '../Question/Option';

const GameGrid = () => {
    return (
        <VStack mt="100px">
            <Question title="Berapa jumlah kemenangan Team RRQ dalam kompetisi MPL sampai saat ini?" image="https://placehold.co/600x400.png" />
            <Question title="Berapa jumlah" />
            <SimpleGrid columns={2} spacingY="24px" spacing="12px" w="100%" pl="20px" pr="20px">
                <Option 
                    key={1}
                    index={0}
                    content="Option ABCD Merdeka ini pilihannya"
                    height="116px"
                />
                <Option 
                    key={1}
                    index={0}
                    content="https://placehold.co/94x64.png"
                    height="116px"
                />
                <Option 
                    key={1}
                    index={0}
                    content="Option ABCD Merdeka ini pilihannya"
                    height="116px"
                    isSelected={true}
                    selectedAnswer="AA"
                />
                <Option 
                    key={1}
                    index={0}
                    content="Option ABCD Merdeka ini pilihannya"
                    height="116px"
                    isSelected={false}
                    selectedAnswer="AA"
                />
                <Option 
                    key={1}
                    index={0}
                    content="Option ABCD Merdeka ini pilihannya"
                    height="226px"
                />
                <Option 
                    key={1}
                    index={0}
                    content="https://placehold.co/94x64.png"
                    height="226px"
                />  
            </SimpleGrid>
            <SimpleGrid columns={1} spacingY="24px" spacing="12px" w="100%" pl="20px" pr="20px" mt="20px">
                <Option 
                    key={1}
                    index={0}
                    content="Option ABCD Merdeka ini pilihannya"
                    height="76px"
                    columns={1}
                />  
                <Option 
                    key={1}
                    index={0}
                    content="https://placehold.co/94x64.png"
                    height="76px"
                    columns={1}
                />  
            </SimpleGrid>
        </VStack>
    )
}

export default GameGrid;