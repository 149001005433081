import React from 'react';
import { Box } from '@chakra-ui/react';

function MobileLayout({ showBg, showRadialGradientBg, showGreyBg, children }) {
  return (
      <Box 
        maxW="500px" 
        mx="auto"
        minH="100vh"
        backgroundImage={showBg && "url('./images/trivia-bg-optimized.jpg')"}
        bg={showRadialGradientBg ? "radial-gradient(73.78% 74.98% at 0% 0%, #4A4A4B 0%, #1B1B1B 100%)" : (showGreyBg && "#242426")}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        {children}
      </Box>
  );
}

export default MobileLayout;