import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { useState } from "react";

const TextInput = ({defaultValue, label, name, isRequired, formatRegex, onChange, type}) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState(defaultValue || "");

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;

    if (formatRegex) {
      const regex = formatRegex;

      if (inputValue === "" || regex.test(inputValue)) {
        setValue(inputValue);
      }
    } else {
      setValue(inputValue);
    }

    setIsActive(
      (!!defaultValue && inputValue !== "") ||
        (inputValue !== "" &&
          (!formatRegex || formatRegex.test(inputValue)))
    );

    onChange && onChange(e);
  };

  return (
    <FormControl id={name} isRequired name={name} h="48px">
      <FormLabel position="absolute" left="12px" top="12px"
        color="#010815" zIndex={100} className={`label ${isActive ? "active" : ""}`}>{label}</FormLabel>
      <Input value={value} type={type || "text"} color="#010815" bg="#E3E3E3" h="100%" className={`input ${isActive ? "active" : ""}`} onChange={onChangeHandler} />
  </FormControl>
  )
}

export default TextInput;