import { Button } from "@chakra-ui/button";
import { Box, Text, VStack } from "@chakra-ui/layout";
import { useState } from "react";
import { useDispatch } from "react-redux";

const Popup = ({ isOpen, title, content, onClose }) => {
    const dispatch = useDispatch();

    const handleClose = (e) => {
        e.preventDefault();
        const className = e.target.className;
        if (className.search('popup-overlay') >= 0 || className.search('popup-close-button') >= 0)
        {
            dispatch(onClose);
        }
    };

    return (
        <Box className="popup-overlay" position="fixed" top={0} bottom={0} left={0} right={0} bg="rgba(0, 0, 0, 0.7)" 
            transition="opacity 500ms" visibility={isOpen ? "visible" : "hidden"} opacity={isOpen ? 1 : 0} onClick={handleClose} zIndex={500}>

            <Box top="calc(50% - 100px)" left="calc(50% - 164px)"  bg="#fff" borderRadius="6px" width="328px"
                position="absolute" transition="all 0.3s ease-in-out" zIndex={600} filter="drop-shadow(0px 4px 0px #F6AC37)"
            >
                <VStack className="popup-content" 
                display="flex" alignItems="center" flexDirection="column" padding="16px"
                >
                    <Text color="#212121" fontSize="32px" fontFamily="Candal">{title}</Text>
                    <Text fontSize="16px" lineHeight="28px" textAlign="center">
                        {content}
                    </Text>
                    <Button
                        mt="36px"
                        colorScheme="orange"
                        variant="solid"
                        type="submit"
                        w="100%"
                        h="48px"
                        boxShadow="0px 6px 0px 0 #A5661D"
                        onClick={handleClose}
                        className="popup-close-button"
                        color="#010815" fontSize="14px" fontWeight={400} fontFamily="Candal"
                    >
                        Tutup
                    </Button>
                
                </VStack>
                <Box className="popup-bottom" />
            </Box>
        </Box>
    )
}

export default Popup;