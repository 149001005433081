import { Box, Text, VStack } from "@chakra-ui/layout";
import LogoBlack from "../Icon/LogoBlack";
import RunIcon from "../Icon/RunIcon";
import { useEffect, useRef, useState } from "react";

const LoadingQuestion = ({name, children, duration }) => {
    const circleRef = useRef();
    const dotRef = useRef();
    const durationInSecond = duration / 1000;
    const [timeLeft, setTimeLeft] = useState(duration / 1000);
    const [count, setCount] = useState(0);
    useEffect(() => {
        const intervalId  = setInterval(() => {
            if(timeLeft === 0){
                clearInterval(intervalId);
              } else {
                setTimeLeft((t) => t - 1);
                setCount((c) => c + 1);
                //numElement.innerText = count;
              }
        }, 1000);

        circleRef.current.style.strokeDashoffset = ( 803 * ( (durationInSecond-timeLeft) / durationInSecond ));
        dotRef.current.style.transform = 
            `rotate(${360 * ((durationInSecond-timeLeft) / durationInSecond)}deg)`;
        
        return () => clearInterval(intervalId);
    }, [timeLeft]);


    useEffect(() => {
        setTimeout(() => {
            circleRef.current.style.transition = "2s stroke-dashoffset";
        }, 400)
    }, [])

    return (
        <VStack gap="20px" display="flex" bg="orange" h="100vh" alignItems="center" textAlign="center" justifyContent="center" p="20px">
            <LogoBlack />
            <Text color="#212121" fontSize="24px" fontWeight={700} lineHeight="34px" >Quiz akan dimulai dalam...</Text>
            <Box display="flex" alignItems="center" justifyContent="center" position="relative" w="280px" h="280px">
                <Box position="absolute" w="272px" h="272px" bgImage={"/images/bg_countdown.png"} />
                <div class="block">
                    <div class="box">
                    </div>
                    <span class="dots" ref={dotRef}></span>
                    <svg class="svg">
                        <defs>
                            <linearGradient id="gradientStyle">
                                <stop offset="0%" stop-color="#fff" />
                                <stop offset="100%" stop-color="#fff" />
                            </linearGradient>
                        </defs>
                        <circle ref={circleRef} class="circle" cx="136" cy="136" r="128" />
                    </svg>
                </div>
                <VStack gap="2px" position="absolute" display="flex" alignItems={"center"}>
                    <Text color="#fff" fontSize="90px" fontWeight={400} fontFamily="Candal">{timeLeft}</Text>
                    <Text letterSpacing="1.12px" color="#fff" fontSize="14px" fontWeight={500}>DETIK</Text>
                </VStack>


                
                
            </Box>
            {children ? children : (
                <>
                <Box borderRadius={8} bg="white" px="8px" py="4px" display="flex" alignItems="center" justifyContent="center">
                <RunIcon />
                <Text ml="4px" color="#212121" fontSize="14px" fontWeight={400}>{name}</Text>
                </Box>
                <Text opacity={0.6} lineHeight="22px" color="#1B1B1B"><b>Penting</b> : Peserta quiz tidak diperbolehkan untuk menekan tombol 🔄 Refresh selama proses berlangsung.</Text>
                </>
            )}
            
        </VStack>
    )
}


export default LoadingQuestion;