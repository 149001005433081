import { Box } from "@chakra-ui/layout";
import { RankAdminTable } from "./RankSlide";

const RankResultAdmin = ({leaderboard}) => {
    return (
        <Box bg="white" paddingTop="100px">
            <RankAdminTable sortedData={leaderboard} />
        </Box>
    )
};

export default RankResultAdmin;