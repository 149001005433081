import { Box, Flex, VStack, Text, Center, HStack } from "@chakra-ui/layout";
import { Correct } from "../Icon/Correct";
import { Incorrect } from "../Icon/Incorrect";
import CorrectBg from "../Icon/CorrectBg";
import IncorrectBg from "../Icon/IncorrectBg";

const CorrectSiluetLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="158" viewBox="0 0 92 158" fill="none">
        <g filter="url(#filter0_d_128_3759)">
        <path d="M-12.5588 84.54L-60 55.3506V1H13.3944C40.6758 1 62.8114 22.1389 62.1918 55.0573C62.0989 60.2336 60.9304 65.3323 58.7621 70.0227C56.5939 74.713 53.4744 78.8903 49.606 82.2834C47.9706 83.722 42.3168 89.4199 34.4136 91.0729L76 157L-1.30127 102.142V70.9552C-1.30127 70.9552 14.8455 69.4489 10.9274 49.3142C10.9274 49.3142 7.02048 34.7195 -12.5588 38.7476V84.54Z" fill="#55D976"/>
        </g>
        <defs>
        <filter id="filter0_d_128_3759" x="-68" y="-7" width="160" height="180" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="4" dy="4"/>
            <feGaussianBlur stdDeviation="6"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.331406 0 0 0 0 0.7875 0 0 0 0 0.442818 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_128_3759"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_128_3759" result="shape"/>
        </filter>
        </defs>
        </svg>
    )
}

const IncorrectSiluetLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="158" viewBox="0 0 92 158" fill="none">
        <g filter="url(#filter0_d_302_4817)">
            <path d="M-12.5588 84.54L-60 55.3506V1H13.3944C40.6758 1 62.8114 22.1389 62.1918 55.0573C62.0989 60.2336 60.9304 65.3323 58.7621 70.0227C56.5939 74.713 53.4744 78.8903 49.606 82.2834C47.9706 83.722 42.3168 89.4199 34.4136 91.0729L76 157L-1.30127 102.142V70.9552C-1.30127 70.9552 14.8455 69.4489 10.9274 49.3142C10.9274 49.3142 7.02048 34.7195 -12.5588 38.7476V84.54Z" fill="#FF6961"/>
        </g>
        <defs>
            <filter id="filter0_d_302_4817" x="-68" y="-7" width="160" height="180" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="4" dy="4"/>
            <feGaussianBlur stdDeviation="6"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.95 0 0 0 0 0.408861 0 0 0 0 0.38 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_302_4817"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_302_4817" result="shape"/>
            </filter>
        </defs>
        </svg>
    )
}

const Result = ({rank, score, isCorrect}) => {
    return (
        <Box w="100%" minH="157px" marginLeft="20px" marginRight="20px" >
            <VStack gap={0} >
                <Box className={`result-top-arc ${isCorrect ? "correct" : "wrong"}`}/>
                <Flex display="inline-flex" width="100%" bg={isCorrect ? "#55D976" : "#FF6961"} borderRadius={8} overflow="hidden" gap={0}>
                    <Box w="25%" display="inline-flex">
                        {isCorrect ? (
                            <CorrectSiluetLogo />
                        ): (
                            <IncorrectSiluetLogo />
                        )}
                    </Box>
                    <Box w="50%" display="flex`" pt="20px">
                        <VStack gap="2px">
                            {isCorrect ? (
                                <>
                                <Correct />
                                <Text fontFamily="Candal" fontSize="20px" fontWeight={400} color="#212121">Benar!!</Text>
                                <Center display="flex" position="relative" textAlign="center">
                                    <CorrectBg />
                                    <Box position="absolute" w="100%" h="100%"  color="white" top="5px" fontWeight={800} fontSize="14px">+{score} points</Box>
                                </Center>
                                </>
                            ): (
                                <>
                                <Incorrect />
                                <Text fontFamily="Candal" fontSize="20px" fontWeight={400} color="#212121">Salah!!</Text>
                                <Center display="flex" position="relative" textAlign="center">
                                    <IncorrectBg />
                                    <Box color="#FFD7D4" position="absolute" top="5px" fontWeight={400} fontSize="12px">Kamu pasti bisa</Box>
                                </Center>
                                </>
                            )}
                            
                            <Text fontSize="12px" fontWeight={900}>Kamu Peringkat: #{rank}</Text>
                        </VStack>
                    </Box>
                    <Box w="25%" transform='rotate(180deg)' display="inline-flex">
                        {isCorrect ? (
                            <CorrectSiluetLogo />
                        ): (
                            <IncorrectSiluetLogo />
                        )}
                    </Box>
                </Flex>
                <Box className={`result-bottom-arc ${isCorrect ? "correct" : "wrong"}`}/>
            </VStack>
        </Box>
    )
}

export default Result;