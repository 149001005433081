import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import CreateRoom from "./pages/CreateRoom";
import Result from "./pages/Result";
import AdminResult from "./pages/admin/Result";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Question from "./pages/test/Question";
import Other from "./pages/test/Other";

const GameRoom = lazy(() => import("./pages/GameRoom"));
const Home = lazy(() => import("./pages/Home"));

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<div />}>
          <Route exact path="/room/:id">
            <GameRoom endpoint={ENDPOINT} />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/c">
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
              <CreateRoom />
            </GoogleReCaptchaProvider>
          </Route>
          <Route exact path="/admin/result">
              <AdminResult />
          </Route>
          <Route exact path="/test/other">
            <Other />
          </Route>
          <Route exact path="/test/question">
            <Question />
          </Route>
          <Route exact path="/result">
            <Result />
          </Route>
        </Suspense>
      </Switch>
    </Router>
  );
};

export default Routes;
