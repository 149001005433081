import {
    Button,
    FormControl,
    FormLabel,
    Select,
    Spacer,
    VStack,
    useDisclosure
  } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useCookies, CookiesProvider } from "react-cookie";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setName, setType, setNumOfQuestion, setQuizId, setRoomId } from "../store/user";
import randomWords from "random-words";
import MobileLayout from "../components/MobileLayout";
import TextInput from "../components/Form/TextInput";
import TriviaTitle from "../components/Misc/Title";
import Popup from "../components/Misc/Popup";
  
  const CreateRoom = () => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { executeRecaptcha } = useGoogleReCaptcha()
    const [isLoggedIn, setIsLoggedIn] = useState(null); 
    const [err, setErr] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['rrq-trivia']);
    const history = useHistory();
    const dispatch = useDispatch();
    const endpoint = process.env.REACT_APP_ENDPOINT;
    const [ quiz, setQuiz ] = useState([]);
  
    useEffect(() => {
        axios.get(`${endpoint}quiz`).then((response) => {
            if(response.status === 200)
            {
                setQuiz(response.data);
            }
        });
    }, [endpoint]);
    
    // Event Handlers
    const createGameHandler = async (event) => {
      event.preventDefault();
      const name = event.target.name.value;
      const numOfQuestion = event.target.numOfQuestion.value;
      const quizId = event.target.quizId.value;
      const maxUser = event.target.maxUser.value;
      dispatch(setName(name));
      dispatch(setNumOfQuestion(numOfQuestion));
      dispatch(setQuizId(quizId));
      dispatch(setType("HOST"));
      const randomRoomID = randomWords(3).join("-");

      const data = {
        "quiz_id": quizId,
        "room_id": randomRoomID,
        "max_user": maxUser,
        "total_question": numOfQuestion
      };

      const response = await axios.post(`${endpoint}quiz/create`, data);

      if(response.status === 200 && response.data.id)
      {
        dispatch(setRoomId(response.data.id));
        history.push(`/room/${randomRoomID}`);
      }
      else {
        setErr(response.data.error);
        dispatch(onOpen)
      }
    };

    const login = async (event) => {
      event.preventDefault();
      const username = event.target.username.value;
      const password = event.target.password.value;

      

      const result = await executeRecaptcha('login')
      const data = {
        "username": username,
        "password": password,
        "token": result
      };
      const response = await axios.post(`${endpoint}login`, data);

      if(response.status === 200 && response.data.success)
      {
        setCookie("name", 'admin', { path: '/' })
        history.go(0);
      }
      else {
        alert('wrong user / password login')
      }
    }

    useEffect(() => {
      if (cookies.name)
      {
        setIsLoggedIn(true)
      }
      else {
        setIsLoggedIn(false)
      }
    }, [])

    return (
      <CookiesProvider>
        <MobileLayout showBg>
          <VStack spacing="14px" h="100vh" alignItems="center" textAlign="center" justifyContent="center" p="18px">
              <TriviaTitle />

              {(!isLoggedIn && !cookies.name) && (
                <form style={{width: "295px"}} onSubmit={login}>
                  <TextInput isRequired name="username" label="User" />
                  <Spacer mt="16px" />
                  <TextInput isRequired name="password" type="password" label="Password" />
                  <Button
                    mt="36px"
                    colorScheme="orange"
                    variant="solid"
                    type="submit"
                    w="100%"
                    h="48px"
                    boxShadow="0px 6px 0px 0 #A5661D"
                  >
                  Login
                  </Button>
              </form>
              )}
              
              
              {(isLoggedIn && cookies.name) && (
                <form style={{width: "295px"}} onSubmit={createGameHandler}>
                    <TextInput isRequired name="name" label="Nama Lengkap" />
                    <Spacer mt="16px" />
                    <TextInput isRequired name="numOfQuestion" type="number" label="Total Pertanyaan" />
                    <Spacer mt="16px" />
                    <TextInput isRequired name="maxUser" type="number" label="Max User" />
                    <Spacer mt="16px" />
                    <FormControl id="quizId" isRequired name="quizId">
                      <FormLabel color="white">Quiz</FormLabel>
                      <Select bg="white" h="48px" placeholder='Select Quiz'>
                          {quiz.map((obj) => (
                          <option value={obj.id} key={obj.id}>{obj.title}</option>
                          ))}
                      </Select>
                    </FormControl>
                    <Button
                      mt="36px"
                      colorScheme="orange"
                      variant="solid"
                      type="submit"
                      w="100%"
                      h="48px"
                      boxShadow="0px 6px 0px 0 #A5661D"
                    >
                    Create
                    </Button>
                </form>
              )}
              
          </VStack>
          <Popup isOpen={isOpen} onClose={onClose} title="Oops!" content={err} />
        </MobileLayout>
      </CookiesProvider>
    );
  };
  
  export default CreateRoom;
  