const TriviaTitle = () => {
    return (
        <h1 style={{
            textAlign: "center",
            fontFamily: "Candal",
            fontSize: "66px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "-2.64px",
            background: "linear-gradient(180deg, #F6AC37 29.1%, #E5A018 83.58%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
        }}>
            RRQ QUIZ
        </h1>
    )
}

export default TriviaTitle;